import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About`}</h1>
    <p>{`Music Production Catalog.`}</p>
    <p>{`All beats are created by `}<a parentName="p" {...{
        "href": "https://beatsbytai.xyz"
      }}>{`beatsbytai`}</a>{` including mixing and mastering.`}</p>
    <p>{`This web app was created by `}<a parentName="p" {...{
        "href": "https://beatsbytai.xyz"
      }}>{`beatsbytai`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      